import cx from "classnames";

import styles from "./helpScreen.module.scss";

type HelpScreenProps = {
  dismissed?: boolean;
  fadeTransition?: boolean;
  onClose: () => void | null;
};

export const HelpScreen = ({
  dismissed,
  fadeTransition,
  onClose,
}: HelpScreenProps) => {
  return (
    <div
      className={cx(styles["help-screen"], {
        [styles["help-screen--dismissed"]]: dismissed,
        [styles["help-screen--fade-transition"]]: fadeTransition,
      })}
    >
      <div className={styles["help-screen__content"]}>
        <h2>Hi!</h2>
        <p>
          This game challenges your ability to decipher connections between a
          list of items then place them in the correct order.
        </p>
        <p>
          Clicking and drag until you think you've solved it, then see if you
          got it right. If not, you can keep guessing until you do! Think you
          have what it takes to get it on the first try?
        </p>
        <p>Have a go. It's Sort Of Fun!</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};
