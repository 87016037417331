import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import cx from "classnames";

import styles from "./sortableCard.module.scss";

type SortableCardProps = {
  id: number;
  children?: React.ReactNode;
  disabled?: boolean;
  correct?: boolean;
};

export const SortableCard = (props: SortableCardProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, active } =
    useSortable({ id: props.id, disabled: props.disabled });

  const style = {
    listStyle: "none",
    position: "relative" as "relative",
    transform: CSS.Transform.toString(transform),
    zIndex: active && active.id === props.id ? "3" : "",
    padding: "4px 0",
    transition,
  };

  return (
    <li style={style}>
      <div
        ref={setNodeRef}
        className={cx(styles["sortableCard"], {
          [styles["sortableCard--correct"]]: props.correct,
        })}
        {...attributes}
        {...listeners}
      >
        <p>{props.children}</p>
      </div>
    </li>
  );
};
