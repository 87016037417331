import React, { useEffect, useState } from "react";
import cx from "classnames";
import shuffle from "lodash.shuffle";
import isEqual from "lodash.isequal";

import { QuestionData, Option } from "../../hooks/useGetData";
import {
  DndContext,
  DragOverlay,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { SortableCard } from "../sortableCard";
import { GameFooter } from "../gameFooter";

import styles from "./cardSection.module.scss";

interface CardSectionProps {
  data: QuestionData;
}

export const CardSection = ({ data }: CardSectionProps) => {
  const [cardData, setCardData] = React.useState<Option[]>();
  const [acceptedOrdersArray, setAcceptedOrdersArray] = useState([[0]]);
  const [hasWonAndSubmitted, setHasWonAndSubmitted] = useState(false);
  const [hasGivenUp, setHasGivenUp] = useState(false);
  const [explanation, setExplanation] = useState("");
  const [guessCount, setGuessCount] = useState(0);

  const gameIsOver = hasGivenUp || hasWonAndSubmitted;

  useEffect(() => {
    setCardData(shuffle(data.options));
    setExplanation(data.explanation);
    setAcceptedOrdersArray(data.acceptedOrders);
  }, [data]);

  const currentOrder = cardData && cardData.map((item) => item.id);
  const hasWon =
    cardData &&
    acceptedOrdersArray &&
    acceptedOrdersArray.some((array) => isEqual(array, currentOrder));

  const submitAnswer = () => {
    setGuessCount(guessCount + 1);
    if (hasWon) {
      setHasWonAndSubmitted(true);
    } else {
      alert("nope!");
    }
  };

  const giveUp = () => {
    setHasGivenUp(true);
    // Correctly order the cards on giving up -
    setCardData((cardData) => cardData?.sort((a, b) => a.id - b.id));
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  if (!cardData) {
    return <></>;
  }

  return (
    <div className={styles.cardSection}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={cardData}
          strategy={verticalListSortingStrategy}
        >
          <ul
            className={cx(styles["cardSectionList"], {
              [styles["cardSectionList--disabled"]]: gameIsOver,
              [styles["cardSectionList--correct"]]: hasWonAndSubmitted,
            })}
          >
            {cardData.map((card) => (
              <SortableCard
                key={card.id}
                id={card.id}
                disabled={gameIsOver}
                correct={hasWonAndSubmitted}
              >
                {card.text}
              </SortableCard>
            ))}
          </ul>
          <DragOverlay dropAnimation={null} />
        </SortableContext>
      </DndContext>
      <GameFooter
        gameIsOver={gameIsOver}
        explanation={explanation}
        guessCount={guessCount}
        submitAnswer={submitAnswer}
        giveUp={giveUp}
        hasWonAndSubmitted={hasWonAndSubmitted}
      />
    </div>
  );

  function handleDragEnd(event: any) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setCardData((cardData) => {
        if (!cardData) {
          return [];
        }
        const oldIndex = cardData.findIndex(
          (cardDataItem) => cardDataItem.id === active.id
        );
        const newIndex = cardData.findIndex(
          (cardDataItem) => cardDataItem.id === over.id
        );
        return arrayMove(cardData, oldIndex, newIndex);
      });
    }
  }
};
