import React, { useState, useEffect } from "react";

export const TimeStamp = () => {
  const [timeStamp, setTimeStamp] = useState("LOADING");

  useEffect(() => {
    async function fetchTimeStamp() {
      const response = await fetch("/data/timestamp.json");
      const data = await response.json();
      setTimeStamp(data.timestamp);
    }
    fetchTimeStamp();
  }, []);

  return <p style={{ display: "none" }}>{timeStamp}</p>;
};
