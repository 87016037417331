import ReactMarkdown from "react-markdown";

import styles from "./gameFooter.module.scss";

type GameFooterProps = {
  gameIsOver: boolean;
  hasWonAndSubmitted: boolean;
  explanation: string;
  guessCount: number;
  submitAnswer: () => void;
  giveUp: () => void;
};

export const GameFooter = ({
  gameIsOver,
  hasWonAndSubmitted,
  explanation,
  guessCount,
  submitAnswer,
  giveUp,
}: GameFooterProps) => {
  return (
    <div className={styles["game-footer"]}>
      {!gameIsOver && (
        <>
          <button onClick={submitAnswer} disabled={gameIsOver}>
            Submit!
          </button>
          <hr />
          <button onClick={giveUp} disabled={gameIsOver}>
            Give up!
          </button>
          {!!guessCount && <p>Guesses - {guessCount}</p>}
        </>
      )}
      {hasWonAndSubmitted && (
        <>
          <h2>WINNER!</h2>
          <p>
            You took {guessCount} {guessCount > 1 ? `guesses` : `guess`}
          </p>
        </>
      )}
      {gameIsOver && (
        <>
          <ReactMarkdown>{explanation}</ReactMarkdown>
          <h4>Social Share Buttons</h4>
        </>
      )}
    </div>
  );
};
