import { useState, useEffect } from "react";

export interface Option {
  id: number;
  text: string;
}

export interface QuestionData {
  data: string;
  explanation: string;
  acceptedOrders: number[][];
  options: Option[];
}

export enum LoadingStatus {
  idle = "IDLE",
  loading = "LOADING",
  fulfilled = "FULFILLED",
  failed = "FAILED",
}

type hookRespones = [QuestionData | null, LoadingStatus, Error | null];

export function useGetData(url: string): hookRespones {
  const [data, setData] = useState<QuestionData | null>(null);
  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.idle);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setData(null);
    setError(null);
    setStatus(LoadingStatus.loading);
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        // Some delay so you see the loading state :)
        setTimeout(() => {
          setStatus(LoadingStatus.fulfilled);
          setData(response as QuestionData);
        }, 1000);
      })
      .catch((e) => {
        setStatus(LoadingStatus.failed);
        setError(e);
      });
  }, [url]);

  return [data, status, error];
}
