import React from "react";
import { useCookies } from "react-cookie";
import { useGetData, LoadingStatus } from "../hooks/useGetData";

import { HelpScreen } from "./helpScreen";
import { CardSection } from "./cardSection";
import { TimeStamp } from "./timeStamp";

import styles from "./app.module.scss";

function App() {
  const dataSource = "/data/todaysQuestion.json";
  const [data, status, error] = useGetData(dataSource);

  const [cookies, setCookie] = useCookies(["return-visitor"]);
  const isFirstVisit = !cookies["return-visitor"];
  const [showHelp, setShowHelp] = React.useState(isFirstVisit);

  React.useEffect(() => {
    // set the return visitor cookie -
    setCookie("return-visitor", true);
  }, [setCookie]);

  React.useEffect(() => {
    if (status === LoadingStatus.fulfilled) {
      document
        .querySelector(".loading-screen")!
        .classList.add("loading-screen--loaded");
    }
  }, [status]);

  return (
    <div className={styles.app}>
      <header className={styles.appHeader}>
        <div className={styles.headline}>
          <TimeStamp />
          <h1>Sort Of Fun</h1>
        </div>
        <div className={styles.menu}>
          <button onClick={() => setShowHelp(!showHelp)}>?</button>
        </div>
      </header>
      {!!error && <p>{`OH NO - THERE HAS BEEN AN ERROR - ${error.cause}`}</p>}
      <HelpScreen
        dismissed={!showHelp}
        fadeTransition={!isFirstVisit}
        onClose={() => setShowHelp(false)}
      />
      {data && <CardSection data={data} />}
    </div>
  );
}

export default App;
